<template>
  <div>
    <ModalRevertRevision
      v-if="showModalRevertRevision"
      :showModal="showModalRevertRevision"
      :revision="selectedRevision"
      :onClickCancel="hideModal"
      @reservationReverted="reservationReverted"
    />

    <p class="title is-4">
      {{ $t('Components.Reservation.ReservationRevisions.Main_Title') }}
    </p>

    <div class="columns">
      <div class="column is-one-fifth">
        <div class="menu">
          <div>
            <label class="checkbox">
              <input type="checkbox" v-model="showCompare" />
              <span>{{
                $t(
                  'Components.Reservation.ReservationRevisions.Checkbox_CompareTo'
                )
              }}</span>
            </label>
          </div>

          <ul class="menu-list">
            <li v-for="(revision, index) in revisions" :key="revision.Id">
              <a
                @click="setSelectedRevision(revision, index)"
                :class="{ 'is-active': selectedIndex === index }"
              >
                <table>
                  <tbody>
                    <tr>
                      <td class="has-text-weight-bold" width="50">
                        #{{ revisions.length - index }}
                      </td>
                      <td class="is-size-7">{{ revision.CreatedByName }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td class="is-size-7 has-text-grey">
                        {{ revision.CreatedOn | dateObjectIsoDateString }}
                        {{
                          revision.CreatedOn
                            | parseIsoDateStringToDate
                            | convertDateTimeToTimeString
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="column">
        <div v-if="selectedRevision">
          <transition name="slide-fade" mode="out-in">
            <div :key="selectedRevision.Id">
              <ui-section-header
                :showEdit="selectedIndex > 0"
                :editFunction="setShowModalRevertRevision"
                :editLabel="
                  $t('Components.Reservation.ReservationRevisions.Icon_Revert')
                "
              >
                <template v-slot:title
                  >{{
                    $t(
                      'Components.Reservation.ReservationRevisions.Subtitle_Revision'
                    )
                  }}
                  #{{ revisions.length - selectedIndex }}</template
                >
              </ui-section-header>

              <div>
                <div>{{ selectedRevision.CreatedByName }}</div>
                <div class="is-size-7">
                  {{ selectedRevision.CreatedOn | dateObjectIsoDateString }}
                  {{
                    selectedRevision.CreatedOn
                      | parseIsoDateStringToDate
                      | convertDateTimeToTimeString
                  }}
                </div>
              </div>

              <reservation-summary
                v-if="selectedRevision"
                :reservationId="selectedRevision.ReservationId"
                :reservation="JSON.parse(selectedRevision.ReservationObject)"
                :showTabs="false"
                :showSubTotals="true"
              />
            </div>
          </transition>
        </div>
      </div>

      <div
        v-if="reservation && showCompare"
        class="column has-background-light"
      >
        <div v-if="selectedRevision">
          <p class="subtitle is-5">
            {{
              $t(
                'Components.Reservation.ReservationRevisions.Subtitle_CurrentStatus'
              )
            }}
          </p>
          <p>&nbsp;</p>

          <reservation-summary
            v-if="selectedRevision"
            :reservationId="reservation.ReservationId"
            :reservation="reservation"
            :showTabs="false"
            :showSubTotals="true"
            :key="reservation.Id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import reservationProvider from '@/providers/reservation'

const ModalRevertRevision = () =>
  import('@/components/Reservations/ModalRevertRevision')
const ReservationSummary = () =>
  import('@/components/Reservations/ReservationSummary')

export default {
  components: {
    ModalRevertRevision,
    'reservation-summary': ReservationSummary,
  },

  props: {
    reservationId: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      hasLoaded: false,
      isSaving: false,
      revisions: [],
      selectedIndex: -1,
      selectedRevision: null,
      showCompare: false,
      showModalRevertRevision: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {
    this.getReservationRevisions()
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    getReservationRevisions() {
      let self = this
      reservationProvider.methods
        .getReservationRevisions(this.reservationId)
        .then((response) => {
          if (response.status === 200) {
            self.revisions = response.data
          }
        })
        .finally(() => {
          self.hasLoaded = true
          self.setSelectedRevision(self.revisions[0], 0)
        })
    },

    reservationReverted(revision) {
      this.revisions.unshift(revision)
    },

    setSelectedRevision(revision, index) {
      this.selectedIndex = index
      this.selectedRevision = revision
    },

    setShowModalRevertRevision() {
      this.showModalRevertRevision = true
    },

    hideModal() {
      this.showModalRevertRevision = false
    },
  },
}
</script>
